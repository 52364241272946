import { AfterViewInit, Component, OnInit, input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faUsers, faIdBadge, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AssessmentService } from 'src/app/services/assessment.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportService } from 'src/app/services/report.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-fire-overview',
  templateUrl: './fire-overview.component.html',
  styleUrl: './fire-overview.component.scss'
})
export class FireOverviewComponent implements AfterViewInit {
  faFileLines = faFileLines;
  faIdBadge = faIdBadge;
  assessment_id = input.required<number>();

  user: any;
  assessment: any;
  fire: any;
  reports: any;
  fireObs = new BehaviorSubject<any>(null);
  updateFireSubject = new Subject<any>();
  isTeamLead = false;
  isCo_or = false;

  constructor(
    public userService: UserService,
    private titleService: Title,
    private readonly assessmentService: AssessmentService,
    private readonly dialogService: DialogService,
    private readonly reportService: ReportService,
  ) {
    this.titleService.setTitle("BAER - Fire Overview")
    this.loadUser()
    this.updateFireSubject.subscribe(() => this.loadAssessments())
  }

  ngOnInit() {
    this.loadAssessments();
  }

  ngAfterViewInit() {

  }

  loadUser() {
    this.userService.getUserProfile().then((user) => {
      this.user = user;
      // 0-Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 7-Washington Office Director
      // 8- Forest BAER Coordinator
      // 12- RO BAER Coordinator
      // 16-WO Coordination
      // 17-WO Logistics
      const isCo_or = user.baer_roles.find((obj: any) => [0, 4, 7, 8, 12, 16, 17].includes(obj.value))
      if (isCo_or) this.isCo_or = true;
      else { //keep highest privilage
        // 1-BAER Team Leader
        // 5-BAER Forest Unit Coordinator
        const isTeamLead = user.baer_roles.find((obj: any) => [1, 5].includes(obj.value))
        if (isTeamLead) this.isTeamLead = true;
      }
    });
  }

  loadAssessments() {
    this.dialogService.openSpinner();
    this.assessmentService.getAssessmentByID(this.assessment_id()).subscribe({
      next: (fire) => {
        this.fire = fire;
        this.fireObs.next(this.fire);
      },
      complete: () => {
        this.dialogService.closeSpinner();
      }
    });
    this.reportService.getReportList(this.assessment_id()).subscribe({
      next: (data) => {
        data.reports.sort((reportA: any, reportB: any) => reportA.report_num - reportB.report_num)
        this.reports = data.reports;
      }
    });  
  }
  
}
