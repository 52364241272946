import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  private link: string = "https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_ForestSystemBoundaries_01/MapServer/1/query?where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=REGION%2C+FORESTORGCODE%2C+FORESTNAME&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=pjson";

  private regionList: string[] = []
  private forestOrgCodeList: string[] = []
  private forestNameList: string[] = []
  private finishedLoading = new Subject<any>()
  private forestNameListByRegion = new Map<string, string[]>();
  private forestOrgCodeListByRegion = new Map<string, string[]>();
  private orgCodeToForestName = new Map<string, string>();
  private forestNameToOrgCode = new Map<string, string>();


  constructor(private httpClient: HttpClient) {
    let offsets = [0, 20, 40, 60, 80, 100]
    Promise.all(offsets.map(async (offset) => {
      await new Promise<void>((resolve) => {
        this.httpClient.get(this.getLink(offset)).subscribe((result: any) => {
          if (result?.features) {
            this.setFeatures(result.features)
          }
          resolve()
        });
      })

    })).then(() => {
      this.regionList.sort((a, b) => a.localeCompare(b))
      this.forestOrgCodeList.sort((a, b) => a.localeCompare(b))
      this.forestNameList.sort((a, b) => a.localeCompare(b))
      this.forestNameListByRegion.forEach((element: string[]) => {
        element.sort((a, b) => a.localeCompare(b))
      })
      this.forestOrgCodeListByRegion.forEach((element: string[]) => {
        element.sort((a, b) => a.localeCompare(b))
      })
      this.finishedLoading.next(null)
    })
  }

  setFeatures(features: any) {
    features.forEach((element: { attributes: any; }) => {
      if (!this.regionList.includes(element.attributes.REGION)) {
        this.regionList.push(element.attributes.REGION)
      }
      if (!this.forestOrgCodeList.includes(element.attributes.FORESTORGCODE)) {
        this.forestOrgCodeList.push(element.attributes.FORESTORGCODE)
      }
      if (!this.forestNameList.includes(element.attributes.FORESTNAME)) {
        this.forestNameList.push(element.attributes.FORESTNAME)
      }

      if (!this.forestNameListByRegion.has(element.attributes.REGION)) {
        this.forestNameListByRegion.set(element.attributes.REGION, [])
      }
      if (!(this.forestNameListByRegion.get(element.attributes.REGION) as string[]).includes(element.attributes.FORESTNAME)) {
        (this.forestNameListByRegion.get(element.attributes.REGION) as string[]).push(element.attributes.FORESTNAME)
      }

      if (!this.forestOrgCodeListByRegion.has(element.attributes.REGION)) {
        this.forestOrgCodeListByRegion.set(element.attributes.REGION, [])
      }
      if (!(this.forestOrgCodeListByRegion.get(element.attributes.REGION) as string[]).includes(element.attributes.FORESTORGCODE)) {
        (this.forestOrgCodeListByRegion.get(element.attributes.REGION) as string[]).push(element.attributes.FORESTORGCODE)
      }

      this.forestNameToOrgCode.set(element.attributes.FORESTNAME, element.attributes.FORESTORGCODE)
      this.orgCodeToForestName.set(element.attributes.FORESTORGCODE, element.attributes.FORESTNAME)
    });
  }

  getLink(offset?: number) {
    return `https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_ForestSystemBoundaries_01/MapServer/1/query?where=1%3D1&f=json&ResultOffset=${offset}&outFields=FORESTNAME%2C+REGION%2C+FORESTORGCODE&ReturnGeometry=false`
  }

  /**
   * GET request for fire assessment data
   *
   * @param page page number of requests
   * @param pageSize size of pages
   * @returns Promise of list of fireAssessments
   */
  getAssessmentList(page?: number, pageSize?: number, filters?: any, sort?: string, sortDirection?: string, isTeamLead?: boolean): Observable<any> {
    let params = new HttpParams()
    let queryParams = ""
    if (page) {
      queryParams += "page=" + page
    }
    if (pageSize) {
      queryParams = this.checkForBlank(queryParams);
      queryParams += "pageSize=" + pageSize
    }
    if(filters?.assessment_status_id != null) {
      filters.assessment_status_ids = [filters.assessment_status_id];
    }
    if (filters) {
      for (let filter in filters) {
        if (filters[filter] !== null) {
          queryParams = this.checkForBlank(queryParams);
          queryParams += `${filter}=${filters[filter]}`
        }
      }
    }
    if (sort) {
      queryParams = this.checkForBlank(queryParams);
      queryParams += "sort=" + sort
    }
    if (sortDirection) {
      queryParams = this.checkForBlank(queryParams);
      queryParams += "sortDirection=" + sortDirection
    }
    if(isTeamLead) {
      queryParams = this.checkForBlank(queryParams);
      queryParams += "loadedForTeamLeader=true"
    }
    if (queryParams != "") {
      queryParams = "?" + queryParams
    }
    return this.httpClient.get(
      `${environment.apiUrl}/assessment${queryParams}`, { params: params }
    );
  }

  checkForBlank(queryParams: string) {
    if (queryParams != "") queryParams += "&"
    return queryParams
  }

  getAssessmentByID(assessment_id: number): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}/assessment/${assessment_id}`
    );
  }

  /**
   * GET request for fire assessment data
   *
   * @param assessmentId assessment_id that is the source of the merge list
   * @returns Promise of list of fireAssessments
   */
  getAssessmentMergeList(assessment_id: number): Observable<any> {
    let params = new HttpParams()
    let queryParams = `?mergedTo=${assessment_id}`
    return this.httpClient.get(
      `${environment.apiUrl}/assessment${queryParams}`, { params: params }
    );
  }

  /**
   * GET request for fire assessment data
   *
   * @returns fire assessment metadata
   */
  getAssessmentMetadata(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/assessment/metadata`);
  }

  /**
   * Sends POST request to create new fire assessment
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
  createNewAssessment(data: {
    baer_name: string,
    region: string,
    forest_name: string,
    forest_org_code: string,
    acres_fsonly: number,
    containment_date?: string,
    containment_date_est?: string,
    assessment_status_id?: number,
    fire_complexity_id?: number
  }): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.post(
      `${environment.apiUrl}/assessment?pageSize=7`, data, { params: params }
    );
  }

  /**
   * Sends PUT request to edit fire specified by assessment_id
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
  editAssessment(data: {
    assessment_id: number,
    baer_name?: string,
    region?: string,
    forest_name?: string,
    forest_org_code?: string,
    acres_fsonly?: number,
    containment_date?: string,
    containment_date_est?: string,
    assessment_status_id?: number,
    fire_complexity_id?: number,
    team_needs_id?: number,
    trainees_needed?: boolean,
    notes?: string,
    close_out_date?: string,
    tentative_start_week?: string,
    team_lead_id?: number,
    support_completed?: boolean | null,
  }): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.put(
      `${environment.apiUrl}/assessment`, data, { params: params }
    );
  }

  /**
   * Merges fires specified in assessment_id_list
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
  mergeAssessments(data: {
    baer_name: string,
    acres_fsonly: number,
    poly_acres?: number,
    containment_date?: string,
    containment_date_est?: string,
    assessment_status_id?: number,
    fire_complexity_id?: number,
    assessment_id_list: number[]
  }): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.post(
      `${environment.apiUrl}/assessment/merge`, data, { params: params }
    );
  }

  /**
   * Sends request for support for assessment, updates assessment with data
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
  requestSupport(data: {
    assessment_id: string,
    support_needed_type?: string,
    support_skills?: string,
    support_details?: string,
  }): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.put(
      `${environment.apiUrl}/assessment`, data, { params: params }
    );
  }

  getRegionList(): Array<string> {
    return this.regionList;
  }

  getForestNameList(region?: string): Array<string> {
    if (region) {
      return this.forestNameListByRegion.get(region) || [];
    }
    return this.forestNameList;
  }

  getOrgCodeList(region?: string): Array<string> {
    if (region) {
      return this.forestOrgCodeListByRegion.get(region) || [];
    }
    return this.forestOrgCodeList;
  }

  getStatusList(): Array<object> {
    return [
      { text: "Pre-size up", value: 1 },
      { text: "Needed", value: 2 },
      { text: "Not Needed", value: 3 },
      { text: "In Progress", value: 4 },
      { text: "Submitted", value: 5 },
      { text: "In Review", value: 6 },
      { text: "Ready For Submission", value: 7 },
      { text: "Signature Requested", value: 8 },
      { text: "Complete", value: 9 },
      { text: "Ready For Review", value: 10 },
      { text: "Forwarded To W.O.", value: 11 },
      { text: "Funding Approved", value: 12},
    ];
  }

  getComplexityList(): Array<object> {
    return [{ text: "Low", value: 1 }, { text: "Moderate", value: 2 }, { text: "High", value: 0 }];
  }

  finishLoadingSubscription(): Observable<any> {
    return this.finishedLoading.asObservable();
  }

  getOrgCodeFromForest(forest: string): string | undefined {
    return this.forestNameToOrgCode.get(forest)
  }

  getForestFromOrgCode(orgCode: string): string | undefined {
    return this.orgCodeToForestName.get(orgCode)
  }

  getBAERTeamNeeds(): Array<object> {
    return [{ text: "Team Needed", value: 0 }, { text: "Covered Locally", value: 1 }, { text: "Covered Regionally", value: 2 }];
  }
}
