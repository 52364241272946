<header class="baer-header">
    <h1 id="table" class="site-page-title">Data Manipulation Page</h1>
    <h4>Data last updated 12/11/2023, 7:00 pm</h4>
</header>
<main id="main-content" class="main-content">
    <div class="button-row">
        <button class="usa-button" (click)="resetAssessmentData()">Reset Assessment Data</button>
        <button class="usa-button" (click)="resetUserData()">Reset User Data</button>
        <button class="usa-button" (click)="editSelfUser()">Edit Self User</button>
        <button class="usa-button red" (click)="deleteSelf()">Delete Self User & Log Out</button>
    </div>
</main>