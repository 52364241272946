import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(private httpClient: HttpClient) { }

    getReportList(assessment_id: number): Observable<any> {
        let params = new HttpParams()
        let queryParams = `?assessment_id=${assessment_id}`
        return this.httpClient.get(
        `${environment.apiUrl}/report${queryParams}`, { params: params }
        );
    }

    createReport(assessment: any, report_num: number): Observable<any> {
        let data = {
            assessment_id: assessment.assessment_id,
            report_status_id: 1, // In Progress
            report_num: report_num,
        };
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/report`, data, { params: params }
        );
    }
}