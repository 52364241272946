<mat-card>
  <mat-card-header>
    <mat-card-title>BAER TEAM NEEDS</mat-card-title>
    <button class="usa-button usa-button--secondary" (click)="edit()" style="min-width: 70px;" *ngIf="editable()" aria-label="Edit Fire">
      <fa-icon [icon]="faEdit" style="color: #216E1F;"></fa-icon>&nbsp;Edit
    </button>

  </mat-card-header>
  <mat-card-content *ngIf="fire">
    <div class="support-warning" *ngIf="fire.support_needed_type == 'Team Lead'">
      <fa-icon [icon]="faWarning" class="faWarning"></fa-icon>
      <div class="support-text">
          <span class="support-title">
              Interregional Support Request
          </span>
          <br>
          <span class="support-description">
            <b>Type of Support:</b> {{ fire.support_needed_type }}
            <br>
            <b>Additional Details:</b> {{ fire.support_details || "--" }}
          </span>
      </div>
    </div>
    <div class="data-div">
      <p class="data-title">BAER Team Needs</p>
      <p class="data-info">{{fire.team_needs?.text || '--'}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">BAER Team Lead</p>
      <p class="data-info" *ngIf="lead">{{lead.first_name + " " + lead.last_name}}</p>
      <p class="data-info" *ngIf="!lead">--</p>
    </div>
    <div class="data-div">
      <p class="data-title">Are you willing to accept trainees?</p>
      <p class="data-info">{{fire.trainees_needed? "Yes" : "No"}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Tentative BAER Start Date</p>
      <p class="data-info">{{(fire.tentative_start_week | date:"MM/dd/YYYY") || "--"}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Team Close-out Date</p>
      <p class="data-info">{{(fire.close_out_date | date:"MM/dd/YYYY") || "--"}}</p>
    </div>
    <div class="data-div">
      <!-- Filler -->
    </div>
    <div class="data-div-large">
      <p class="data-title">Notes</p>
      <p class="data-info">{{fire.notes || '--'}}</p>
    </div>
  </mat-card-content>
</mat-card>