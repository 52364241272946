    <table mat-table [dataSource]="reports" class="report-table">
        <ng-container matColumnDef="filler">
            <th mat-header-cell *matHeaderCellDef class="filler-column" style="width: 48px;"></th>
            <td mat-cell *matCellDef="let report" class="filler-column"></td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>2500-8 Reports</th>
            <td mat-cell *matCellDef="let report">{{assessment_name}}({{report.report_num}})</td>
        </ng-container>
        <ng-container matColumnDef="reportId">
            <th mat-header-cell *matHeaderCellDef>Report ID</th>
            <td mat-cell *matCellDef="let report" class="prewrap">{{report.report_id}}</td>
        </ng-container>
        <ng-container matColumnDef="reportStatus">
            <th mat-header-cell *matHeaderCellDef>Report Status</th>
            <td mat-cell *matCellDef="let report">
                <mat-chip *ngIf="report.report_status_id" [ngClass]="'chip-r-'+report.report_status_id">
                    {{report.report_status_id | reportStatusBadge}}
                </mat-chip>
                <span *ngIf="!report.report_status_id">--</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="baerTeam">
            <th mat-header-cell *matHeaderCellDef>BAER Team Members</th>
            <td mat-cell *matCellDef="let report" class="prewrap">
                <mat-chip *ngIf="report.users.length != 0; else usersDef" style="margin-left: 5px;" [matTooltip]='report.tooltip'
                    matTooltipClass="tooltipClass">
                    <fa-icon class="fa-sm" [icon]="faUsers"></fa-icon> {{report.users.length}}+
                </mat-chip>
                <ng-template #usersDef>--</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 270px;">
                <div class="info-containment-div"></div>
            </th>
            <td mat-cell *matCellDef="let report" style="width: 270px;">
                <!-- Only show edit report if In-Progress or Ready To Submit -->
                <button class="edit-btn" *ngIf="(isTeamLead || isCo_or) && [4, 7].includes(report.report_status_id)"><fa-icon class="fa-sm" [icon]="faEdit"></fa-icon>Edit Report</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
                <div class="no-data">
                    <p>No reports have been created</p>
                </div>
            </td>
        </tr>
    </table>