<main id="main-content" class="main-content">
    <div class="card top-card" style="display: flex;">
        <div class="sub-title-div">
            <h2>2500-8 Reports</h2>
        </div>
        <div class="button-row">
            <button *ngIf="fire.assessment_status_id == 2" class="usa-button long-button" (click)="createInitialReport()"><mat-icon>add</mat-icon>
                Create Initial Report</button>
            <button *ngIf="fire.assessment_status_id != 2" class="usa-button longer-button" (click)="createInterimReport()"><mat-icon>add</mat-icon>
                Create Interim Report</button>
        </div>
    </div>
    <div class="card">
        <app-view-reports
        [(edit_fire)]="edit_fire" 
        [fire]="fire" 
        [reports]="reports" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        [isTeamLead]="isTeamLead"
        [isCo_or]="isCo_or"
        ></app-view-reports>
    </div>
    <div class="card">
        <div class="sub-title-div">
            <h2>Fire Overview</h2>
        </div>
        <app-view-fire 
        [(edit_fire)]="edit_fire" 
        [fire]="fire" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        ></app-view-fire>
    </div>
    <div class="card">
        <app-view-team-needs 
        [(edit_fire)]="edit_fire" 
        [fire]="fire" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        [isTeamLead]="isTeamLead"
        [user]="user"
        ></app-view-team-needs>
    </div>
    <div class="card">
        <app-view-forest-contacts
        [(edit_fire)]="edit_fire" 
        [fire]="fire" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        ></app-view-forest-contacts>
    </div>
</main>