<main id="main-content" class="main-content" *ngIf="user">
    <div style="display: flex;">
        <div class="record_count">
            {{length}} Requests
        </div>
    </div>
    <div class="search-bar">
        
        <mat-form-field appearance="outline" style="width: 330px;">
            <mat-label>Fire/Complex or Report Name</mat-label>
            <input matInput [(ngModel)]="filters.baer_name" aria-label="Fire/Complex Name">
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 120px;">
            <mat-label>Region</mat-label>
            <mat-select [(value)]="filters.region" (selectionChange)="regionChanged()">
                <mat-option [value]="null">--</mat-option>
                @for (region of regions; track region) {
                <mat-option [value]="region">{{region}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 190px;">
            <mat-label>Request Status</mat-label>
            <mat-select [(value)]="filters.support_completed">
                <mat-option [value]="false">PENDING</mat-option>
                <mat-option [value]="true">COMPLETE</mat-option>
                <mat-option [value]="undefined">--</mat-option>
            </mat-select>
        </mat-form-field>
        <button class="usa-button" (click)="search()"><mat-icon>search</mat-icon>Search</button>
        <button class="usa-button usa-button--secondary" (click)="clearSearch()" *ngIf="!filters_clean">Clear
            Filters</button>
    </div>
    <table mat-table [dataSource]="assessments" class="center" matSort matSortDisableClear multiTemplateDataRows
        (matSortChange)="sortChange($event)">
        
        <ng-container matColumnDef="baer_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fire/Complex or Report Name</th>
            <td mat-cell *matCellDef="let assessment">{{assessment.baer_name || "--"}} </td>
        </ng-container>

        <ng-container matColumnDef="region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
            <td mat-cell *matCellDef="let assessment" class="prewrap">{{assessment.region | line_break}}</td>
        </ng-container>
        

        <ng-container matColumnDef="support_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Status</th>
            <td mat-cell *matCellDef="let assessment" class="prewrap">
                @if (assessment.support_completed) {
                    <mat-chip class="chip-5">COMPLETE</mat-chip> 
                } @else {
                    <mat-chip class="chip-2">PENDING</mat-chip> 
                }
            </td>
        </ng-container>

        
        
        <ng-container matColumnDef="support_needed_type">
            <th mat-header-cell *matHeaderCellDef>Type of Support Requested</th>
            <td mat-cell *matCellDef="let assessment">
                @if (assessment.support_needed_type) {
                    <mat-chip>{{ assessment.support_needed_type }}</mat-chip> 
                } @else {
                    <span>--</span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="support_skills">
            <th mat-header-cell *matHeaderCellDef>Skill(s) Requested</th>
            <td mat-cell *matCellDef="let assessment">

                @if (assessment.main_skill) {
                    <mat-chip>{{ assessment.main_skill }}</mat-chip> 
                    <mat-chip 
                        *ngIf="assessment.other_skill_count"
                        class="other-skills"
                        [matTooltip]='assessment.support_skills'
                        matTooltipClass="tooltipClass"
                    >+{{ assessment.other_skill_count }}</mat-chip> 
                } @else {
                    <span>--</span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="support_details">
            <th mat-header-cell *matHeaderCellDef>Additional Details</th>
            <td mat-cell *matCellDef="let assessment">
                <span>{{ assessment.support_details || "--" }}</span>
            </td>
        </ng-container>


        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 270px;">Actions</th>
            <td mat-cell *matCellDef="let assessment" style="width: 270px;">
                <div class="action-div">

                    @if (assessment.support_needed_type == "Team Lead") {
                        <button class="first-action"
                            (click)="editBaerTeamNeeds(assessment); stopPropagation($event)">
                            <fa-icon class="fa-sm" [icon]="faEdit"></fa-icon>Edit Team Needs
                        </button>
                        <button class="menu-btn" [matMenuTriggerFor]="menu" aria-label="Individual assessment action list"
                            #t="matMenuTrigger" (click)="stopPropagation($event)" class="{{t.menuOpen ? 'menuOpen' : ''}}">
                            @if (t.menuOpen) {
                            <mat-icon>keyboard_arrow_up</mat-icon>
                            } @else {
                            <mat-icon>keyboard_arrow_down</mat-icon>
                            }
                        </button>
                        <mat-menu #menu="matMenu" class="action-menu" yPosition="below">
                            <button mat-menu-item>
                                <fa-icon class="fa-sm" [icon]="faUsers"></fa-icon>
                                <span>View Team Roster</span>
                            </button>
                        </mat-menu>
                    } @else {
                        <button class="btn-only">
                            <fa-icon class="fa-sm" [icon]="faUsers"></fa-icon>View Team Roster
                        </button>
                    }
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <!-- <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="13">
                <div class="no-data">
                    <div class="no-data-icon">
                        <mat-icon class="no-data-icon1">search</mat-icon>
                        <mat-icon class="no-data-icon2">whatshot</mat-icon>
                    </div>
                    <div class="no-data-title">No assessments found</div>
                    <p>There are no assessments that match your current filters. Try removing some of them to get better
                        results.</p>
                    <button class="usa-button usa-button--secondary" (click)="clearSearch()">Clear Filters</button>
                </div>
            </td>
        </tr>
    </table>
    <mat-paginator [length]="length" [pageIndex]="pageNumber" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 50]"
        showFirstLastButtons (page)="pageEvent($event)" aria-label="Select page of fires">
    </mat-paginator>
</main>