import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserMemberService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    ) { }

  getUserMember(user_id: number): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.get(
      `${environment.apiUrl}/report/member/${user_id}`, { params: params }
    );
  }
}